
<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Chi-Square"
            right-nav="reset"
            @click="doReset"
        />
        <div>
            <div
                class="inline-input-view d-flex"
            >
                <input-single
                    v-model="df"
                    label="df"
                    :tip="getTip('df')"
                    style-class="input-single group"
                    group
                    @update="onChangeDf()"
                    @enter-pressed="onSubmit()"
                />
                <button
                    class="action-btn mr-5"
                    @click="showModal()"
                >
                    Formula
                </button>
            </div>

            <div class="m-view d-flex py-1 mt-4">
                <h3 class="mx-2">
                    P ( 𝛘² >
                </h3>
                <input
                    v-model="z"
                    v-b-tooltip.hover.bottom="getTip('z')"
                    class="eq-inline-input"
                    placeholder="?"
                    @input="onChangeZ()"
                    @keyup.enter="onSubmit()"
                >
                <h3 class="mx-2">
                    ) =
                </h3>
                <input
                    v-model="prob"
                    v-b-tooltip.hover.bottom="getTip('prob')"
                    class="eq-inline-input"
                    placeholder="?"
                    @input="onChangeProb()"
                    @keyup.enter="onSubmit()"
                >
            </div>

            <highcharts
                class="pt-2 pb-4 m-auto"
                :options="zChart.chartOptions"
            />
            <modal
                ref="modalComponent"
                title="Formula"
                style-class="modal-bottom"
                modal-type="general"
            >
                <template #body>
                    <img
                        class="formulaImage"
                        alt="binomial formula"
                        src="@/assets/helpImgs/ChiSquareDistribution.jpg"
                    >
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import Modal from '@/components/modal.vue'
import InputSingle from '@/components/input-single.vue'
import normalData from '@/js/distributions/normal-data.js'
import chiSquareData from '@/js/distributions/chi-and-f-data.js'
import errorText from '@/js/lib/error-handle-text.js'
import { isEmptyNumsCustom, isInvalidNums, isNotPosWholeNumberDF, clearData } from '@/js/lib/input-check.js'
import { tips } from '@/js/lib/tooltip-text.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'ChiSquare',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        Modal,
        InputSingle
    },
    data () {
        return {
            computeType: 'chi',
            graphType: 'normalSkewed',
            seriesPoint: '',
            posHighSkew: '',
            μ: '0', // mu (Mean)
            σ: '1', // sigma (Standard Deviation)
            df: '', // degrees of freedom
            z: '', // Point
            prob: '', // Probability
            selected: { value: '>' },
            graphData: []
        }
    },
    computed: {
        zChart () {
            var { graphData } = this
            return {
                chartOptions: {
                    chart: {
                        type: 'area',
                        spacing: [null, -10, 35, 25],
                        spacingTop: 0,
                        height: 350,
                    },
                    xAxis: {
                        endOnTick: true,
                        startOnTick: true,
                        tickLength: 0,
                        tickInterval: 1,
                        max: _.get(graphData, 'max', 3),
                        min: _.get(graphData, 'min', -3),
                        labels: {
                            enabled: false,
                        },
                        plotLines: _.get(graphData.zones, 'plotLineVals', []),
                    },
                    yAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        enabled: false,
                    },
                    series: [
                        { data: _.get(graphData, 'series', normalData.loadSkewedGraphSeries(0, 1)) }, // fix default
                    ],
                    plotOptions: {
                        area: {
                            zoneAxis: 'x',
                            zones: [{
                                fillColor: 'white',
                                value: _.get(graphData.zones, 'zLowZone', [])
                            }, {
                                value: _.get(graphData.zones, 'zHighZone', [])
                            }, {
                                fillColor: 'white',
                            }]
                        }
                    }

                }
            }
        },
    },
    methods: {
        getTip (key) {
            var tipsData = _.merge(tips.general, tips.chiSquareDistribution)
            return _.get(tipsData, key)
        },
        doReset (keepData) {
            var inputs = ['z', 'prob', 'df']
            if (!keepData) {
                inputs = _.concat(inputs, ['graphData'])
            } else {
                inputs = _.difference(inputs, keepData)
            }
            _.assign(this, clearData(this, inputs))
        },
        showModal () {
            this.$refs.modalComponent.show()
        },
        onChangeDf () {
            this.doReset(['df'])
        },
        onChangeZ () {
            this.doReset(['z', 'df'])
        },
        onChangeProb () {
            this.doReset(['prob', 'df'])
        },
        onSubmit () {
            var { μ, σ, df } = this
            if (isEmptyNumsCustom(df)) {
                alert(errorText.distributionAlert.firstInputDf)
                return
            }
            if (isInvalidNums(df) || isNotPosWholeNumberDF(df)) {
                return
            }
            var output = chiSquareData.calculateData(this)
            if (!output) {
                return
            }
            _.assign(this, output)

            const graphOutput = normalData.calculateGraphData(this, μ, σ)
            _.set(this, 'graphData', graphOutput)
        },
    }
}
</script>
